import {pipe, isServerOn} from './Often.js';

const STYLE_SERVER_TAG_NAME = ['f_b', 'f_i', 'f_u', 'f_del'];

export function convertOnCopy(txt = '') {
    return pipe(customTrim, rn2n, html2tag, //removeAllTag
    )(txt);
}

/**
 *
 * @param txt
 * @param type - {POST|REMARK|CHAT}
 * @returns {*}
 */
export function convertOnSave(txt = '', type = 'POST') {
    if (type === 'POST') {
        return pipe(convertHashServerTag, convertMentionServerTag, removeUrlTag, //mobile
            TagUtil.splitStyle, convertStyleServerTag, styleServerTag2TempTag, convertDiv2Newline, styleTempTag2ServerTag, removeStyleClassTag, removeCloseTag, html2tag, //mobile
        )(txt);
    }
    if (type === 'REMARK') {
        return pipe(convertMentionServerTag, removeUrlTag, //mobile
            convertDiv2Newline, removeStyleClassTag, removeCloseTag, html2tag, //mobile
        )(txt);
    }
    if (type === 'CHAT') {
        return pipe(removeUrlTag, //mobile
            convertDiv2Newline, customTrim, removeStyleClassTag, removeCloseTag, html2tag, //mobile
        )(txt);


    }
    console.warn('No Case', txt);
    return pipe(removeStyleClassTag, removeCloseTag, html2tag, //mobile
    )(txt);
}

export function convertOnRead(txt = '') {
    return pipe(styleServerTag2TempTag, tag2html, removeStyleClassTag, removeCloseTag, styleTempTag2ServerTag,)(txt);
}

export function removeUrlTag(txt) {
    /**
     * Cover Case
     * 1. hi <span class="js-hyper-button urllink blue">www.naver.com</span> kkk
     * 2. hi <span class="js-hyper-button urllink blue">www.naver.com</span> kkk <span class="js-hyper-button urllink blue">www.naver.com</span>
     * 3. hi <span class="js-hyper-button urllink blue">www.naver.com</span><span class="js-hyper-button urllink blue">www.naver.com</span>
     * 4. hi <a href="www.naver.com" targer="_blank" class="js-hyper-button urllink blue">www.naver.com</a>
     * 5. hi <a href="www.naver.com" targer="_blank" class="js-hyper-button urllink blue">www.naver.com</a> kkk <a href="www.naver.com" targer="_blank" class="js-hyper-button urllink blue">www.naver.com</a>
     * 6. <div><span class="tag mention-span" user-id="koreaso2003" contenteditable="false">이령홍</span>&nbsp;</div> <div><span data-test="woon-test" class="js-woon-test js-hyper-button urllink blue">https://wooncloud.tistory.com/88/</span></div> <div><a href="https://release.flow.team/main.act" target="_blank" class="js-hyper-button urllink blue">https://release.flow.team/main.act</a> adfasdfasd</div>
     */
    const pattern = /(?:(?:<span|<a)(?:[^>]*?) class="js-hyper-button urllink blue(?:.*?)">(.*?)<\/(?:span>|a>))/ig;
    return txt.replace(pattern, '$1');
}

export function removeMentionTag(txt) {
    const pattern = /(?:<span class="tag mention-span.*?(?=user-id)user-id="(?<id>[^"]+)"[^>]*>(?<name>[^\[\]<>]+)<\/span>)/ig;
    return txt.replace(pattern, '$2');
}

export function convertHtmlCode(txt = '') {
    return html2tag(txt)
}

function convertMentionServerTag(txt) {
    /*
    * Cover Case
    * 1. <span class="tag mention-span" contenteditable="false" user-id="dnjswhdzld@yopmail.com">원종1</span> <span>23</span>
    * 2. <span class="tag mention-span" contenteditable="false" user-id="dnjswhdzld@yopmail.com">원종123</span>
    * 3. <span class="tag mention-span" user-id="dnjswhdzld@yopmail.com" contenteditable="false">원종123</span>
    * 4. <span class="tag mention-span cursorsHover" contenteditable="false" user-id="dnjswhdzld@yopmail.com">원종123</span>
    * 5. <span class="tag mention-span cursorsHover"  user-id="dnjswhdzld@yopmail.com" contenteditable="false">원종123</span>
    * 6. <span class="tag mention-span" user-id="dnjswhdzld@yopmail.com">원종123</span> v <span class="tag mention-span" user-id="dnjswhdzld@yopmail.com">원종123</span>
    * */
    const pattern = /(?:<span class="tag mention-span.*?(?=user-id)user-id="(?<id>[^"]+)"[^>]*>(?<name>[^\[\]<>]+)<\/span>)/ig
    return txt.replace(pattern, '@[$2]($1)');
}


function convertHashServerTag(txt) {
    /*
    * Cover Case
    * 1. <span class="tag hashtag-span" contenteditable="false">#태그1</span> <span>23</span>
    * 2. <span class="tag hashtag-span" contenteditable="false">#태그2</span>
    * 3. <span class="tag hashtag-span cursorsHover" contenteditable="false">#태그 123</span>
    * 4. <span class="tag hashtag-span">#태그</span> v <span class="tag hashtag-span">#태그</span>
    * */
    const pattern = /(?:<span class="tag hashtag-span(.*?)(?=">#)">#([^\[\]<>]+)<\/span>)/ig;
    return txt.replace(pattern, '#[$2]($2)');
}

function convertStyleServerTag(txt) {
    /*
    * Cover Case
    * 1. 안녕<b>하세</b>요 안녕<strong>하세</strong>요
    * 2. 안녕<b style="">하세</b>요 안녕<strong style="">하세</strong>요
    * 3. 안녕<b style="user-select: auto;">하세</b>요 안녕<strong style="user-select: auto;">하세</strong>요
    * 4. 안녕<b style="user-select: auto;"><u style="user-select: auto;">하</u>세</b>요
    * 5. 안녕<br><b>안녕하세요</b>asd
    * 6. 안녕<b >123</b>
    * */
    const boldPattern = /(<b>|<b ([^<>]*?)>)(.*?)(<\/b>)|(<strong>|<strong ([^<>]*?)>)(.*?)(<\/strong>)/ig;
    const italicPattern = /(<i>|<i ([^<>]*?)>)(.*?)(<\/i>)|(<em>|<em ([^<>]*?)>)(.*?)(<\/em>)/ig;
    const underlinePattern = /(<u>|<u ([^<>]*?)>)(.*?)(<\/u>)/ig;
    const strikePattern = /(<strike>|<strike ([^<>]*?)>)(.*?)(<\/strike>)/ig;
    return txt
        .replace(boldPattern, '<f_b>$3</f_b>')
        .replace(italicPattern, '<f_i>$3</f_i>')
        .replace(underlinePattern, '<f_u>$3</f_u>')
        .replace(strikePattern, '<f_del>$3</f_del>');
}

function convertDiv2Newline(txt) {
    const isReal = isServerOn("REAL");
    const isWrapDivOrP = (node) => ['DIV', 'P'].includes(node.nodeName);
    const isOnlyHaveBRInServerTempTag = (node) => {
        const brPattern = /\[\[(f_b|f_i|f_u|f_del)]](<br>)\[\[\/(f_b|f_i|f_u|f_del)]]/ig;
        const innerHtml = node.innerHTML;
        return innerHtml.match(brPattern)?.length > 0;
    }
    const isBR = (node) => 'BR'.includes(node.nodeName);
    const isWrapStyleServerTag = (node) => STYLE_SERVER_TAG_NAME.includes(node.nodeName.toLowerCase());
    const removeBRLastChildNode = (node) => {
        const lastChildNode=  node.childNodes[node.childNodes.length - 1];
        if(isBR(lastChildNode)) node.removeChild(lastChildNode);
    }
    const singleNode2html = (node) => {
        const _text = tag2html(node.textContent);
        //Note. 텍스트노드 마지막 문자에 개행이 있다면 추가하기않기
        const isExistsNewLineInLast = _text.match(/(\r\n|\r|\n)$/)?.length > 0;
        return ({
            '#text': _text + (isExistsNewLineInLast ? '' : '\n'), 'BR': '\n',
        }[node.nodeName] || console.warn('error singleNode2html!'));
    }

    const getDivEl = (txt) => {
        const divEl = document.createElement("div");
        divEl.innerHTML = txt;
        return divEl;
    }

    const pushData = (txt, node, flag) => {
        Mutil.clog('pushData', {txt, node, flag});
        resultData.push(txt);
    }

    const resultData = [];
    const divEl = getDivEl(txt);
    const isOnlyOneNode = divEl.childNodes.length === 1;
    const isOnlyTextOneNode = isOnlyOneNode && divEl.childNodes[0].childNodes.length === 0;

    if (isOnlyTextOneNode) {
        pushData(txt, '', 'only text one');
    } else {
        div2newline(txt);
    }
    Mutil.clog('resultData', resultData);
    return resultData.join('');

    /*
    * Cover Case
    * 1. 1\n2\n\n3\n>4
    * 2. <div>1</div><div>2</div><div><br></div><div>3</div><div>4</div>
    * 3. <div>1</div><div><div>3</div><div>4</div></div>
    * 4. 1\n2\n<br>3\n>4
    * 5. <div>123123<br></div>
    * */
    function div2newline(txt) {
        getDivEl(txt).childNodes.forEach((node) => {
            //Case1. 123 | <br> | 1231231 \n 123123 | 12312312 \n
            if (node.childNodes.length === 0) {
                pushData(singleNode2html(node), node, 'first text');
                return;
            }

            //Case2. <div>123</div> | <div><br></div> | <f_b>123</f_b>
            if (node.childNodes.length === 1) {
                const childNode = node.childNodes[0];
                if (isWrapDivOrP(node)) {
                    pushData(singleNode2html(childNode), childNode, 'childNode');
                    return;
                }

                if (isWrapStyleServerTag(node)) {
                    pushData(node.outerHTML, node, 'style');
                    return;
                }

                pushData(node.innerText, node, 'etc');
                // console.warn('div2newline find case2', node, node.nodeName);
                return;
            }

            //case 4 <div>[[f_b]]<br>[[/f_b]]</div> | <div>[[f_i]][[f_b]]<br>[[/f_b]][[/f_i]]</div>
            if(node.childNodes.length >= 3 && isWrapDivOrP(node) && isOnlyHaveBRInServerTempTag(node)) {
                pushData('\n', node, 'style');
                return;
            }

            // Case3. <div>123141<br></div>
            if (isWrapDivOrP(node)) {
                removeBRLastChildNode(node);
            }

            //Case5. <div>123</div> 123
            return div2newline(node.innerHTML);
        })
    }
}

function html2tag(txt = '') {
    const pattern = /(&lt;|&gt;|&amp;|&nbsp;|&#x2F;)/ig;
    return txt.replace(pattern, (v) => ({
        '&lt;': '<', '&gt;': '>', '&amp;': '&', '&nbsp;': ' ', '&#x2F;': '/'
    }[v]));
}

function customTrim(cntn) {
    /**
     * Cover Case
     * 1.(공백)(엔터)\n1\n2\n(엔터)(공백)
     */
    return cntn.replace(/(^(\s|\\n)*)|((\s|\\n)*$)/g, "");
}

function removeAllTag(txt = '') {
    return txt.replace(/<(\/)?([a-zA-Z]*)(\s[a-zA-Z]*=[^>]*)?(\s)*(\/)?>/ig, '');
}

function removeStyleClassTag(txt = '') {
    //<div style="">, <p style="">, <span style="">
    return txt.replace(/<([a-zA-Z]*)(\s(style|class)=[^>]*)(\s)*(\/)?>/ig, '');
}

function removeCloseTag(txt = '') {
    //</div>, </p>, </span>ㅁㄴㄹㄴㄹ
    return txt.replace(/<(\/)([a-zA-Z]*)>/ig, '');
}

function tag2html(txt = '') {
    if('SEM_1'==_USE_INTT_ID) return txt.replace(/[<>&]/ig, (v) => ({'<': '&lt;', '>': '&gt;', '&' : '&'}[v]));
    else return txt.replace(/[<>&]/ig, (v) => ({'<': '&lt;', '>': '&gt;', '&': '&amp;'}[v]));
}

function styleServerTag2TempTag(txt) {
    /**
     * Cover Case
     * 1. <div>11</div>
     * 2. 11<f_u>11</f_u>11
     * 3. 11<f_b>11</f_b>11
     * 4. 11<f_del>11</f_del>11
     * 5. 11<f_i>11</f_i>11
     * 6. 11<div><br></div>22
     * 7. <p>좋아요</p>
     */
    return txt.replace(/(<(\/?)((f_b)|(f_del)|(f_u)|(f_i))>)/ig, '[[$2$3]]');
}

function styleTempTag2ServerTag(txt) {
    return txt.replace(/(\[\[(\/?)(f_b|f_del|f_u|f_i)]])/ig, '<$2$3>');
}

function rn2n(txt) {
    //Note. https://www.fileformat.info/info/unicode/char/2028/index.htm
    const LineSeparatorCharCode = 8232;
    txt = txt.split('').map(v => v.charCodeAt(0) === LineSeparatorCharCode ? '\n' : v).join('');
    return txt.replace(/(\r\n|\r|\n)/g, '\n');
}

/**
 * 개행 - Test Case
 * 0. 채팅
 * 0-1. 채팅 등록
 * 0-2. 채팅 조회 복사 등록 | 채팅 에딧 복사 등록
 * 0-3. 글에서 복사해서 채팅 등록 | 업무에서 복사해서 채팅 등록
 * 1. 댓글
 * 1-1. 댓글 개행 등록
 * 1-2. 댓글 개행 수정 -> 조회 복사 | 에딧 복사 -> 등록
 * 1-3. 댓글 개행 사이에 멘션 추가 => 등록
 * 1-4. 메모장 탭 등록
 * 2. 글
 * 2-1. 글 개행 등록
 * 2-2. 글 개행 수정 -> 조회 복사 | 에딧 복사 -> 등록
 * 2-3. 스타일 태그 등록 | 수정
 * 2-4. 멘션 태그 등록 | 수정
 * 2-5. 해시 태그 등록 | 수정
 * 2-6. 파일 추가 등록 | 이동 => 수정
 * 2-7. 메모장 탭 등록 | 조회 복사 | 에딧 복사 -> 수정
 * 3. 업무
 * 3-1. 업무 개행 등록
 * 3-2. 업무 개행 수정 -> 조회 복사 | 에딧 복사 -> 등록
 * 3-3. 업무 댓글 개행 사이에 멘션 추가 => 등록
 * 3-4. 메모장 탭 등록
 * 4. 일정
 * 4-1. 일정 개행 등록
 * 4-2. 일정 개행 수정 -> 조회 복사 | 에딧 복사 -> 등록
 */