import {n2v} from "./Often";

const removeLocal = (k) => localStorage.removeItem(convertKeyByTabId(k));
const setLocal = (k, v) => localStorage.setItem(convertKeyByTabId(k), v);
const getLocal = (k) => localStorage.getItem(convertKeyByTabId(k));
const setLocalJson = (k, json) => localStorage.setItem(convertKeyByTabId(k), JSON.stringify(json));
const getLocalJson = (k) => n2v(getLocalValue(k), {});
const setLocalValue = (k, key, value) => {
    try {
        let tempJson = {...JSON.parse(getLocal(k))};
        tempJson[key] = value;
        setLocal(k, JSON.stringify(tempJson));
    } catch (e) {
    }
};
const getLocalValue = (k, key) => {
    try {
        if (key) return JSON.parse(getLocal(k))[key];
        return JSON.parse(getLocal(k));
    } catch (e) {
        return n2v(getLocal(k));
    }
};

//같은 계정으로 두 가지 탭을 켜는 경우에 발생할 수 있는 이슈를 해결하기 위함
function convertKeyByTabId(k) {
    k = k || '';
    if (k.includes("TAB_")) return "N_" + k + "_" + n2v(window._TAB_ID); //같은 계정이라도 탭 별로 정보를 달리 보관
    return "N_" + k; //같은 계정이라면 하나만 보관
}

export {removeLocal, setLocal, getLocal, setLocalJson, getLocalJson, setLocalValue, getLocalValue}