const stringify = v => v ? JSON.stringify(v) : '';
const parse = (v, isArray) => {
    if (v === null) return isArray ? [] : {}
    try {
        return JSON.parse(v.replace(/\n/g, '\\n').replace(/\r/g, '\\r'));
    } catch (e) {
        return isArray ? [] : {}
    }
}
const parseJSON = (v, isArray) => {
    if (v === null) return isArray ? [] : {}
    try {
        return JSON.parse(v.replace(/\n/g, '\\n').replace(/\r/g, '\\r'));
    } catch (e) {
        return v;
    }
}
const encrypt = v => {
    try {
        return btoa(stringify(v)).replace(/=/g, '');
    } catch (e) {
        //console.error('encrypt error', v);
        return "";
    }
}
const decrypt = v => {
    try {
        return parse(atob(v));
    } catch (e) {
        //console.error('decrypt error', v);
        return {}
    }
}

export {stringify, parse, parseJSON, encrypt, decrypt}