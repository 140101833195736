export class Paging {

  constructor(pageData) {
    this.pageData = {
      ...pageData,
      PG_NO: 1,
      NEXT_YN: 'Y'
    };
  }

  getPageData() {
    return this.pageData;
  }

  setPageData(pageData) {
    this.pageData = {...this.pageData, ...pageData};
  }

  setNextPage() {
    this.setPageData({PG_NO: this.pageData.PG_NO + 1})
  }

  refreshData() {
    this.pageData.PG_NO = 1;
    this.pageData.NEXT_YN = 'Y';
  }
}

