class Socket {
    #socket;
    #props;

    constructor(props = {}) {
        this.#props = props;
        this.#setup();
    }

    #setup() {
        const {NODE_URL = ''} = this.#props;
        const nodeUrl = NODE_URL;
        const options = this.#getOptions();
        try {
            this.#socket = io.connect(nodeUrl, options);
        } catch {
            console.error('undefined socket io');
        }
    }

    #getOptions() {
        return {
            rememberUpgrade: true
        }
    }

    emit(eventName = '', data = {}) {
        if (!eventName) {
            return console.error('empty eventName');
        }
        this.#socket.emit(eventName, data);
    }

    on(eventName = '', callback) {
        if (!eventName) return console.error('empty eventName');
        if (typeof callback !== 'function') return console.error('empty callback');

        this.#socket.off(eventName).on(eventName, callback);
    }
}

export const connectSocket = (props) => new Socket(props);