import {Paging} from "./Paging.js";
import {RecordControl} from "./RecordControl.js";
import {load, removeLoading} from "../Loading.js";
import {noop} from "../Often.js";

/**
 * @param
 *
 */
export class VirtualScroll {
    constructor({ target = {}, pageData = {}, renderOption = {}, observeOption = {}, eventOption = {}, callOption = {}}) {
        this.target = target;
        this.renderOption = renderOption;
        this.observeOption = observeOption;
        this.eventOption = eventOption;
        this.callOption = callOption;
        this.isApiCalling = false;

        this.setup(pageData);
        this.initSkeleton();
        this.setEvent()
    }

    setup(pageData) {
        this.pageController = new Paging(pageData ?? {NEXT_YN: 'N', LIMIT: 10});
        this.recordController = new RecordControl([]);

    }

    setEvent() {
        const {$ul} = this.target;
        const {click = noop} = this.eventOption;
        click && $ul.off('click').on('click', click);
    }

    async callAndRender({isDrawLoad = false} = {}) {
        const {$ul} = this.target;
        if (isDrawLoad) {
            load({type: 'circle', $area: $ul})
        }

        if (this.callOption.beforeApiCall && typeof this.callOption.beforeApiCall == 'function') {
            this.callOption.beforeApiCall({pageData: this.pageController.getPageData()})
        }

        this.isApiCalling = true;
        const response = await this.callOption.callApi();
        this.isApiCalling = false;

        if (this.callOption.afterApiCall && typeof this.callOption.afterApiCall == 'function') {
            this.callOption.afterApiCall({pageData: this.pageController.getPageData(), response})
        }

        this.render(response);
    }

    render(response) {
        if (!this.recordController.records) {
            console.error(this);
            return;
        }

        const isFirstDraw = this.pageController.pageData.PG_NO === 1;
        const isNoData = this.recordController.records.length === 0;
        const {$ul, $scroll} = this.target;
        let {final} = this.callOption;
        let {item} = this.renderOption;


        final = final || noop;
        item = item || noop;

        removeLoading($ul);
        isFirstDraw && $ul.empty();
        const $lis = () => $ul.children();
        const liLength = $lis().length;
        if (isNoData) {
            this.pageController.setPageData({NEXT_YN: "N"});
        } else {
            this.pageController.setPageData({PG_NO: this.pageController.pageData.PG_NO + 1});
            let addRecords = [...this.recordController.records];
            if (this.pageController.getPageData().ORDER_TYPE === 'P') {
                addRecords.splice(-1 * liLength)
                $ul.prepend(item(addRecords));
            } else {
                $ul.append(item([...this.recordController.records].splice(liLength)));
            }
        }

        this.setScroll();

        if (isFirstDraw) {
            if (isNoData) {
                $ul.append(this.renderOption.noDataHtml);
            } else {
                $ul.scrollTop(0);
            }
        }

        final({$ul, $scroll, isFirstDraw, response});
    }

    setScroll() {
        const {$ul, $scroll} = this.target;
        let {ratio, isLastIndexPaging, showCallback, hideCallback} = this.observeOption;
        let {item} = this.renderOption;

        const $lis = () => $ul.children();
        if (this.postsIo) {
            $lis().each((_, el) => this.postsIo.observe(el));
            return;
        }

        const observeCallback = (entries) => {
            entries.forEach(entry => {
                const {target, intersectionRatio, isIntersecting} = entry;
                const $t = $(target);
                const idx = $lis().index($t);
                const lastIdx = this.recordController.records.length - idx === 1;

                //영역이 안 보이기 시작했어
                if (intersectionRatio === ratio && !isIntersecting) {
                    hideCallback && hideCallback({$target:$t, mode: 'saveDraftRemark', isHided: true});
                    $t.css('min-height', $t.height())
                    $t.html('');
                    return;
                }

                //영역이 보이기 시작했어
                if (intersectionRatio > ratio && isIntersecting) {
                    if ($t.html() === '') {
                        $t.html($(item([this.recordController.records[idx]])).html());
                    }

                    if(showCallback && typeof showCallback === 'function') showCallback({$item: $t})
                    $t.css('min-height', '1px')
                    if (isLastIndexPaging && lastIdx && this.recordController.records.length === $lis().length && this.pageController.pageData.NEXT_YN === 'Y') {
                        if (this.isApiCalling) return;

                        this.callAndRender().then(r => {});
                    }
                }
            })
        }

        this.postsIo = new IntersectionObserver(observeCallback, {
            threshold: [0, 1],
            root: $scroll[0],
            rootMargin: '150px 0px'
        });

        $lis().each((_, el) => {
            this.postsIo.observe(el)
        });
    }

    unobserve() {
        const {$ul} = this.target;
        const $lis = () => $ul.children();
        $lis().each((_, el) => {
            this.postsIo?.unobserve(el)
            el.remove()
        });
    }

    initSkeleton() {
        if (this.target.$ul.html().trim() !== "") return;
        this.target.$ul.html(LoadSkeleton.getPostContentsByDetail())
    }
}
