import {noop} from "./Often";
import {load, removeLoading} from "./Loading";

const SCROLL_RATIO = 0.8;

/**
 * 페이징이 필요한 API의 경우
 * 필터링, 스크롤에 따라 인풋 데이터가 다양해지므로
 * 해당 지역에서 데이터들을 관리하여 활용한다.
 */

class Paging {

    constructor(data) {
        let {
            pageData = {},
            noDataHtml = "",
            callback = {},
            targetObj = {},
        } = data;

        this.pageData = pageData;
        this.noDataHtml = noDataHtml;
        this.callback = callback;
        this.targetObj = targetObj;
        this.timer = null;

        this.init({view: true, data: true})
        this.setEvent();
    }

    init({view, data}) {
        this.timer = null;
        const {$ul} = this.targetObj;
        if (view) $ul.empty();
        if (data) {
            this.pageData.PG_NO = 1;
            this.pageData.NEXT_YN = 'Y';
        }
    }

    setPageData(pageData) {
        this.pageData = {...this.pageData, ...pageData};
    }

    getPageData() {
        return this.pageData;
    }

    setRecords(records) {
        this.records = records;
    }

    //next 함수와 callback.next 옵션은 엄연히 다름
    async next({isScrolling = false, isDrawLoad = false} = {}) {
        const {$ul} = this.targetObj;
        if (isDrawLoad) {
            load({type: 'circle', $area: $ul})
        } else {
            !isScrolling && this.init({view: true});
        }
        await this.callback.next({isScrolling, isDrawLoad, $area: $ul});
    }

    setEvent() {
        const {$ul, $scroll = $ul, $focus = $()} = this.targetObj;
        const {click = noop, next: nextCallback = noop} = this.callback;
        $ul.off('click').on('click', click);
        $scroll.off("scroll").on("scroll", ({target: {scrollTop, clientHeight, scrollHeight}}) => {
            const currentRatio = (scrollTop + clientHeight) / scrollHeight;
            const {NEXT_YN = ""} = this.pageData;
            if (NEXT_YN === 'N') return;
            this.timer && clearTimeout(this.timer);
            this.timer = setTimeout(() => {
                if (scrollTop === 0) return;
                if (SCROLL_RATIO < currentRatio) {
                    nextCallback({isScrolling: true, isDrawLoad: false, $area: $ul});
                }
                clearTimeout(this.timer);
            }, 500);
        });
        $focus.focus();
    }

    render() {
        if (!this.records) {
            console.error(this);
            return;
        }

        const isFirstDraw = this.pageData.PG_NO === 1;
        const isNotData = this.records.length === 0;
        const {$ul} = this.targetObj;
        let {final, item} = this.callback;

        final = final || noop;
        item = item || noop;

        removeLoading($ul);

        if (isNotData) {
            if (isFirstDraw) $ul.append(this.noDataHtml);
            this.setPageData({NEXT_YN: "N"})
        } else {
            $ul.append(item(this.records));
            if (isFirstDraw) $ul.scrollTop(0);
            this.setPageData({PG_NO: this.pageData.PG_NO + 1})
        }

        final({$ul, isFirstDraw});
    }

}

export {Paging}