import {isTarget, exist, noop} from "./Often";

const $confirm = () => $("#confirm");

function confirm(options = {}) {

    const $body = $('body');
    const {text = {}, callback = {}, display = {}} = options;
    const {
        contents = i18next.t(common.askChange, {val : dictionary.status}),
        subContents = '',
        more = '',
        submit = i18next.t(dictionary.confirm) || '확인',
        cancel = i18next.t(dictionary.cancel) || '취소',
    } = text;
    const {
        cancelBtn = true
    } = display;

    const moreText = more.replace(/\r\n/g, '<br>');

    const closeConfirmPop = () => {
        $confirm().remove();
        $("#confirmStyle").remove();
        $body.css('overflow', 'auto');
    }

    const {
        submitFn = closeConfirmPop,
        cancelFn = closeConfirmPop,
        finalFn = noop,
    } = callback;

    closeConfirmPop();
    setConfirmStyle();

    $body.prepend($(getConfirmHtml({
        contentsText: contents,
        subContentsText : subContents,
        moreText,
        cancelText: cancel,
        submitText: submit,
        cancelBtnDisplay: cancelBtn
    })));
    $confirm().on("mousedown mouseup click", (e) => {
        e.stopPropagation();
    })
    $confirm().on("click", async (e) => {
        const $confirm = $(e.currentTarget);
        switch (true) {
            case isTarget(e, ".cancel"):
            case !isTarget(e, ".confirm-content-area") :
                await cancelFn($confirm);
                closeConfirmPop();
                break;
            case isTarget(e, ".contents-more") :
                const $contentsMore = $(e.target).closest(".contents-more");
                const isActive = $contentsMore.hasClass("active");
                $contentsMore.next()[isActive ? 'slideUp' : 'slideDown'](400);
                $contentsMore.toggleClass("active");
                break;
            case isTarget(e, ".submit") :
                await submitFn($confirm);
                closeConfirmPop();
                break;
            default:
                break;
        }
    })
    $confirm().fadeIn(200);
    finalFn($confirm());
    $body.css('overflow', 'hidden');
}

function getConfirmHtml({contentsText, subContentsText , moreText, cancelText, submitText, cancelBtnDisplay}) {
    return `
        <div id="confirm" class="dimmed-area" tabindex="0">
            <div class="confirm-content-area">
                <div><p>${contentsText}</p>
                      ${exist(subContentsText) ? `<p style="color:#FE6565">${subContentsText}</p>` : ''}  
                </div>
                ${exist(moreText) ? `<div>
                    <div class="contents-more">${i18next.t(common.showDetail)}</div>
                    <div class="contents-more-area">${moreText}</div>
                </div>` : ''}
                <div class="btn-area">
                    ${cancelBtnDisplay ? `<div class="cancel">${cancelText}</div>` : ''}
                    <div class="submit">${submitText}</div>
                </div>
            </div>
        </div>
    `
}

function setConfirmStyle() {
    if ($("#confirmStyle").length > 0) return;
    $("head").append(`
        <style id="confirmStyle">
        .dimmed-area {
            position: fixed;
            display: flex;
            align-items: center;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: rgba(0,0,0,.6);
            /*z-index: 101;*/
            z-index: 2101;
        }
        .confirm-content-area {
            background: #fff;
            border-radius: 10px;
            width: 450px;
            margin: 0 auto;
            padding: 20px;
        }
        .confirm-content-area p:first-child {
            margin-top: 12px;
        }
        .confirm-content-area p:last-child {
            margin-bottom: 25px;
        }
        .confirm-content-area p {
            /*margin: 12px 0 25px 0;*/
            text-align: center;
            white-space: break-spaces;
        }
        .confirm-content-area .contents-more {
            text-align: center;
            color: #aaa;
            margin-bottom: 20px;
            cursor: pointer;
        }
        .confirm-content-area .contents-more::after {
            position: relative;
            top: -2px;
            left: 3px;
            display: inline-block;
            content: "";
            width: 8px;
            height: 6px;
            background-image: url(/flow-renewal/assets/images/sprite-all-task.png);
            background-position: -403px -3px;
            background-size: 922px auto;
        }
        .confirm-content-area .contents-more.active::after {
            background-position: -427px -3px;
        }
        .confirm-content-area .contents-more-area {
            display: none;
            max-height: 200px;
            padding: 20px;
            color: rgb(170, 170, 170);
            background: rgb(238, 238, 238);
            margin: 20px 0;
            border-radius: 5px;
            overflow-y: auto;
            overflow-x: hidden;
        }
        .confirm-content-area .btn-area {
            width: 100%;
            text-align: center;
        }
        .confirm-content-area .btn-area div {
            display: inline-block;
            width: 47%;
            line-height: 34px;
            border: 1px solid #ddd;
            border-radius: 4px;
            color: #555;
            font-size: 13px;
            cursor: pointer;
        }
        .confirm-content-area .btn-area .submit {
            color: #fff;
            background: #6449fc;
            margin-left: 8px;
        }
        </style>`);
}

export {confirm}