import {toast} from './Toast';
import {n2v} from './Often'

const Validate = (function () {

    const makeMessage = (name, number) => {
        const enNen = isFinalTreatment(name) ? "은" : "는";
        //const iga = isFinalTreatment(v) ? "이" : "가";
        return ({
            digits: {
                max: i18next.t(okr.maximumLetterWrite, {val : name, count : number}),
                min: i18next.t(okr.minimumLetterNecessity, {val : name, count : number}),
                require: i18next.t(okr.essentialValue, {val : name}),
            },
            count: {
                max: i18next.t(okr.maximumCaseWrite, {val : name, count : number}),
                min: i18next.t(okr.minimumCaseNecessity, {val : name, count : number}),
                require: i18next.t(okr.essentialValue, {val : name}),
            }
        })
    }

    function digits(name, value = "", maxNumber, minNumber) {
        value = String(n2v(value)).trim();
        return minMax("digits", name, value, maxNumber, minNumber);
    }

    function count(name, arr = [], maxCount, minCount) {
        return minMax("count", name, arr, maxCount, minCount);
    }

    function minMax(code, name, value = "", max, min) {
        let errorMessage = "";
        if (value instanceof Array) {
            //pass
        } else {
            value = String(n2v(value)).trim();
        }
        if (value.length < +min) {
            errorMessage = makeMessage(name, min)[code][min === 1 ? "require" : "min"];
        } else if (value.length > +max) {
            errorMessage = makeMessage(name, max)[code]["max"];
        }
        if (errorMessage === "") return true;
        toast("error", errorMessage);
        return false;
    }

    return {digits, count}
})()

function isFinalTreatment(v) {
    const l = String(v).length;
    v = String(v).substring(l - 1, l);
    const r = (v.charCodeAt(0) - parseInt('0xac00', 16)) % 28;
    return +r !== 0;
}

export {Validate}