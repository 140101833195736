import {exists} from "./Often.js";

function openLoadingPop($area, props = {}) {
    const {type = 'straight', percent = 0, contents = ''} = props;
    const waitingContents = i18next.t(common.wait) || contents;
    const $loadingPop = $(loadingPop(waitingContents)).attr('id', 'loadingPop');
    load({type, $area: $loadingPop.find(".load-area")});
    $loadingPop.find(".loading-area").css({margin: '10px 0'});
    $loadingPop.css({'z-index': 20});
    $area.prepend($loadingPop);
}

function updateLoadingPop(props) {
    const {percent = 0, contents = ''} = props;
    const $loadingPop = $('#loadingPop');
    if (exists(contents)) {
        $loadingPop.find('.content-area').find('p').text(contents);
    }

    $loadingPop.find('.progress-bar').css({width: percent + '%'});
    $loadingPop.find('.progress-bar').text(percent + '%');
}

function load({type, $area, where = 'full', style = {}}) {
    if (!$area) return;

    setLoadingStyle();
    removeLoading($area);

    const _html = {
        "circle": loadCircle(),
        "straight": loadStraight(),
        "progress": loadingProgress(),
        "progress2": loadingProgress2(),
    }[type] || loadStraight();

    const $html = $(_html);
    $html.css(style);

    switch (where) {
        case "full" :
            $area.html($html);
            break;
        case "prepend" :
            $area.prepend($html);
            break;
        case "append" :
            $area.append($html);
            break;
        default :
            break;
    }

    if (type === "progress") {
        const $progressBar = $html.find('.load-progress-bar');
        $progressBar.animate({width: "0%"}, 1500, () => {
            $progressBar.animate({width: "60%"}, 1000, () => {
                $progressBar.animate({width: "80%"}, 2000, () => {
                    $progressBar.animate({width: "100%"}, 5000);
                });
            });
        })
    }
}

function closeLoadingPop($area = $('body')) {
    $('#loadingPop').remove();
}

function removeLoading($area = $('body')) {
    $area.find(".loading-area, .load-progress-area").remove();
}

const loadingProgress = () => {
    return `<li class="load-progress-area"><div class="load-progress-bar"></div></li>`;
}

const loadingProgress2 = () => {
    return `
        <div class="progress">
            <div class="progress-bar bg-success"
                 role="progressbar" 
                 aria-label="Success example"  
                 style="width: 0"></div>
        </div>
    `;
}

const loadingPop = (contents) => {
    return `
        <div id="confirm" class="dimmed-area" tabindex="0">
            <div class="content-area">
                <div><p>${contents}</p></div>
                <div class="load-area"></div>
            </div>
        </div>
    `
}

const loadCircle = () => {
    return `
        <div class="loading-area">
            <div class="loading type2">
                <i class="circle"></i>
                <i class="circle"></i>
                <i class="circle"></i>
                <i class="circle"></i>
                <i class="circle"></i>
            </div>
        </div>
    `
}

const loadInnerStraight = () => {
    return `
        <div class="loading">
            <i class="circle"></i>
            <i class="circle"></i>
            <i class="circle"></i>
            <i class="circle"></i>
        </div>
    `
}

const loadStraight = () => {
    return `<div class="loading-area">${loadInnerStraight()}</div>`;
}

function setLoadingStyle() {
    if ($("#LoadingStyle").length > 0) return;
    $("head").append(`
<style id="LoadingStyle">
.dimmed-area {
    position: absolute;
    display: flex;
    align-items: center;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0,0,0,.6);
    z-index: 11;
}
.content-area {
    background: #fff;
    border-radius: 10px;
    width: 450px;
    margin: 0 auto;
    padding: 20px;
}
.content-area p {
    margin: 12px 0 25px 0;
    text-align: center;
    white-space: pre;
}
.circle {
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
}
.circle:nth-child(1) {
    background: #00b2ff;
    animation-name: loading;
    animation-duration: 0.7s;
    animation-delay: 0.2s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
}
.circle:nth-child(2) {
    background: #00b01c;
    animation-name: loading;
    animation-duration: 0.7s;
    animation-delay: 0.3s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
}
.circle:nth-child(3) {
    background: #fd7900;
    animation-name: loading;
    animation-duration: 0.7s;
    animation-delay: 0.5s;
}
.circle:nth-child(4) {
    background: #402a9d;
    animation-name: loading;
    animation-duration: 0.7s;
    animation-delay: 0.6s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
}

@keyframes loading {
    0% {
        transform: translateY(0);
    }
    20% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(0);
    }
    60% {
        transform: translateY(0);
    }
    80% {
        transform: translateY(-10px);
    }
    100% {
        transform: translateY(0);
    }
}

.loading-area {
    grid-column: 1/10;
    margin: 200px 0;
    width: 100%;
    text-align: center;
}
.loading.type2 {
    width: 24px;
    height: 24px;
    position: relative;
    display: inline-block;
}
.loading.type2 .circle {
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    opacity: 0.2;
    animation: dot 1s ease-in-out infinite;
}
.loading.type2 .circle:nth-child(1) {
    background: #00b2ff;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    animation-delay: 0s;
}
.loading.type2 .circle:nth-child(2) {
    top: 6.5px;
    right: 0;
    background: #00b01c;
    animation-delay: 0.1s;
}
.loading.type2 .circle:nth-child(3) {
    bottom: 0;
    right: 3px;
    background: #fd7900;
    animation-delay: 0.2s;
}
.loading.type2 .circle:nth-child(4) {
    bottom: 0;
    left: 3px;
    background: #402a9d;
    animation-delay: 0.3s;
}
.loading.type2 .circle:nth-child(5) {
    top: 6.5px;
    left: 0;
    background: #777777;
    animation-delay: 0.4s;
}

@keyframes dot {
    0% {opacity: 0.2;}
    20% {opacity: 0.2;}
    40% {opacity: 1;}
    60% {opacity: 1;}
    80% {opacity: 1;}
    100% {opacity: 0.2;}
}

.load-progress-area {
    background-color: #eee !important;
    padding: 0 !important;
    margin: 0 !important;
    position: fixed !important;
    height: 2px !important;
    width: 400px !important;
}

.load-progress-bar {
    /*height: 0.1rem;*/
    height: 2px;
    margin: 0;
    opacity: 0.75;
    width: 0;
    background-color: #6449fc;
}
</style>`);
}

export {openLoadingPop, load, closeLoadingPop, updateLoadingPop, removeLoading, loadInnerStraight}
