import {getFileInfo, isImageExtension, json2formData} from "./File";
import {toast} from "./Toast";
import {n2v} from "./Often";
import {parse} from "./Json";

export const uploadOneFile = (limitSize, isCamaraOpt = false) => {
    return new Promise((resolve) => {
        const $input = isCamaraOpt ? $(`<input type="file" accept="image/*" capture hidden>`) : $(`<input type="file" hidden>`);
        const removeInput = () => $input.remove();
        $input.on("change", e => {
            const value = $(e.target).val();
            const {extension} = getFileInfo(value);

            const files = (e.srcElement || e.target).files;
            const oneFile = files[0];

            if(!!limitSize && limitSize < oneFile.size) return toast("error", "파일은 10MB이하만 첨부가능합니다.");

            if(isImageExtension(extension) === true) {
                putImageFile(oneFile).subscribe(formData => {
                    sendFile(formData, resolve);
                });
            } else {
                putFile(oneFile, resolve);
            }
            removeInput();
        })

        $('body').append($input);
        $input.trigger('click');
    })
}

function putFile(file, resolve) {
    const formData = json2formData({
        USER_ID: window._USER_ID,
        RGSN_DTTM: window._RGSN_DTTM,
        NOW: new Date().getTime(),
        FILE: file,
        FILE_NAME: file.name,
    });

    sendFile(formData, resolve);
}

export const uploadImage = () => {
    return new Promise((resolve) => {
        const $input = $(`
        <input type="file"  accept="image/*" hidden>`);
        const removeInput = () => $input.remove();
        $input.on('change', (e) => {
            const value = $(e.target).val();
            const {extension} = getFileInfo(value);
            if (isImageExtension(extension) === false) {
                toast("error", "Not Image!");
                return;
            }
            const files = (e.srcElement || e.target).files;
            const oneFile = files[0];
            putImageFile(oneFile).subscribe(formData => {
                sendFile(formData, resolve);
            });
            removeInput();
        })
        $('body').append($input);
        $input.trigger('click');
    })
}

function putImageFile(file) {
    return Rx.Observable.create(observer => {
        const reader = new FileReader();
        const image = new Image();
        reader.onload = () => {
            image.onload = () => {
                const formData = json2formData({
                    USER_ID: window._USER_ID,
                    RGSN_DTTM: window._RGSN_DTTM,
                    NOW: new Date().getTime(),
                    FILE: file,
                    WIDTH: n2v(image.width),
                    HEIGHT: n2v(image.height),
                    FILE_NAME: file.name,
                });
                observer.next(formData);
            }
            image.src = reader.result;
        }
        reader.readAsDataURL(file);
    });
}

function sendFile(formData, resolve) {
    const uploadURL = "/FLOW_UPLOAD_C001.jct";
    const xhr = new XMLHttpRequest();

    xhr.upload.addEventListener('loadstart', () => Mutil.clog('onLoadStart'), false);
    xhr.upload.addEventListener('progress', () => Mutil.clog('onProgress'), false);
    xhr.upload.addEventListener('abort', () => Mutil.clog('onAbort'), false);
    xhr.upload.addEventListener('error', () => Mutil.clog('onError'), false);
    xhr.onreadystatechange = function (e) {
        const res = parse(e.target.responseText);
        if (Object.keys(res).length === 0) return;
        const {COMMON_HEAD, FILE_REC} = res;
        if (COMMON_HEAD.error || !FILE_REC || FILE_REC.length === 0) {
            if(COMMON_HEAD?.ERROR && COMMON_HEAD?.MESSAGE) toast("error", COMMON_HEAD.MESSAGE);
            resolve({});
            return;
        }
        resolve(FILE_REC[0]);
    };
    xhr.open("POST", uploadURL, true);
    xhr.send(formData);
}