let workingTimer;

const $toast = () => $("#toast");

function toast(type = "info", message) {
    setToastStyle();
    $toast().remove();
    $('body').prepend($(getToastHtml({type, message})));
    $toast().fadeIn(200);
    if (workingTimer) clearTimeout(workingTimer);
    workingTimer = setTimeout(() => {
        $toast().fadeOut(200, () => $toast().remove());
    }, 2500);
}

function getToastHtml({type, message}) {
    return `
        <div id="toast" class="alert-wrap">
            <div class="alert-type ${type}">
                <div class="text">${message}</div>
            </div>
        </div>
    `
}

function setToastStyle() {
    if ($("#toastStyle").length > 0) return;
    $("head").append(`
        <style id="toastStyle">
        .alert-wrap {
          display: none;
          z-index: 112;
          top: 140px;
          left: 50%;
          -webkit-transform: translateX(-50%);
          -ms-transform: translateX(-50%);
          transform: translateX(-50%);
          position: fixed;
        }
        .alert-wrap .text {
          white-space: pre;
          word-break: keep-all;
        }
        .alert-type {
          border-radius: 50px;
          padding: 9px 17px;
          text-align: center;
          font-size: 13px;
          z-index: 13;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
        }
        .alert-type.success {
          background: #6449fc;
          border: 1px solid #6449fc;
          color: #fff;
        }
        .alert-type.info {
          background: #623ad6;
          border: 1px solid #623ad6;
          color: #fff;
        }
        .alert-type.error {
          background: #ff3434;
          border: 1px solid #ff3434;
          color: #fff;
        }
        .alert-wrap.mini .alert-type {
          font-size: 13px;
        }
        </style>`);
}

export {toast}